import React from "react"
import PropTypes from "prop-types"
import SuperEllipse from "react-superellipse";
import {Preset} from "react-superellipse";

function LinksList(props) {
  const list = props.links.map((link, index) =>
    <li>
      <a href={ link.link } className="btn white-outline-btn data-no-turbo data-no-turbolinks multilink-link">
        <span className="multilink-link-text">{ link.name }</span>
        <span className="multilink-link-img">
          <img src={ link.image } style={ {height: '25px'} } />
        </span>
        
      </a>
    </li>
  );
  return (
    <ul className="list-unstyled">{list}</ul>
  );
}

class MultilinkCard extends React.Component {

  render () {
    return (
        <React.Fragment>
          <SuperEllipse r1={0.01} r2={0.22} style={{width: this.props.width, height: this.props.height}}>
            <div className="card card-black container card-multilink">
              <SuperEllipse r1={0.01} r2={0.2} style={{width: '100%', height: '100%'}}>
                <img src={this.props.pic} style={{width: '100%', height: '100%'}}/>
              </SuperEllipse>
              <br/>
              <h3 className="container medium-thin multilink-name mb-0 pb-0">
                { this.props.name }
              </h3>
              <h4 class="container medium-thin multilink-artist mt-0">
                { this.props.artist }
              </h4>
              <br/>
              <LinksList links={this.props.links} height={this.props.height} width={this.props.width}/>
            </div>
          </SuperEllipse>
        </React.Fragment>
    );
  }
}

MultilinkCard.propTypes = {
  height: PropTypes.node,
  width: PropTypes.node,
  pic: PropTypes.node,
  html: PropTypes.node,
  links: PropTypes.node
}

export default MultilinkCard

