// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()

require("jquery")

require("channels")
require("bootstrap")
require("bootstrap-select")
require("activestorage")

import "chartkick"

//= require jquery3
//= require popper
//= require bootstrap-sprockets
//= require jquery
//= require best_in_place

//= require jquery-ui
//= require best_in_place.jquery-ui

//= require bootstrap/alert
//= require bootstrap/dropdown

//= require bootstrap-select


function valueChanged()
{
    if($('.rights-owned').is(":checked"))
        $(".submit-button").show();
    else
        $(".submit-button").hide();
}

$(document).on("click", ".rights-owned", valueChanged());

if (window.location.hash.length > 0) {
    setTimeout(function() {
        window.scrollTo(0, $(window.location.hash).offset().top);
        }, 100);
}

function goToAnchor() {
    let hash = document.location.hash;
    if (hash !="") {
        setTimeout(function() {
            if (location.hash) {
                window.scrollTo(0, 0);
                window.location.href = hash;
            }
        }, 1);
    }
    else {
        return false;
    }
}

$(document).on("page:load", goToAnchor());


$(document).on('page:load', function(){
    $('.selectpicker').selectpicker('render');
});
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// inside app/frontend/js/bootstrap_js_files.js   

import "bootstrap"
// import "turbolinks"

require("trix")
require("@rails/actiontext")



const cs = require("query-string")

window.showInitData = function() {
    document.getElementById("myText").innerHTML = decodeURIComponent(
        window.Telegram.WebApp.initData.toString());
}


require('htmx.org')
var htmx = require('htmx.org')

htmx.onLoad(function(content) {
    var sortables = content.querySelectorAll(".sortable");
    for (var i = 0; i < sortables.length; i++) {
      var sortable = sortables[i];
      new Sortable(sortable, {
          animation: 150,
          ghostClass: 'blue-background-class'
      });
    }
})

import Sortable from 'sortablejs';


require("ilyabirman-jouele")
require("howler")