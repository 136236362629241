import React from "react"
import PropTypes from "prop-types"
import SuperEllipse from "react-superellipse";
class ReleaseCard extends React.Component {
  render () {
    return (
      <React.Fragment>
        <React.Fragment>
          <SuperEllipse r1={0.01} r2={0.18} style={{width: this.props.width, height: this.props.height}}>
            <div className="card card-black container card-multilink">
              <SuperEllipse r1={0.01} r2={0.18} style={{width: '100%', height: '100%'}}>
                <img src={this.props.pic} style={{width: '100%', height: '100%'}}/>
              </SuperEllipse>
              <div dangerouslySetInnerHTML={{__html: this.props.html}} />
            </div>
          </SuperEllipse>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default ReleaseCard
