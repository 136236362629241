import React from "react"
import PropTypes from "prop-types"
import SuperEllipse from "react-superellipse";
import Squircle from "./Squircle";
class ReleaseCard extends React.Component {
    render () {
        return (
                <React.Fragment>
                    <SuperEllipse r1={0.01} r2={0.18}
                                  style={{
                                      width: this.props.width,
                                      height: this.props.height,
                                      'background-image': this.props.image}}>
                        <h3>{this.props.header}</h3>
                        <p>{this.props.body}</p>
                    </SuperEllipse>
                </React.Fragment>
        );
    }
}

Squircle.propTypes = {
    height: PropTypes.node,
    width: PropTypes.node,
    image: PropTypes.node,
    header: PropTypes.node,
    body: PropTypes.node
};

export default ReleaseCard
